<template>
  <div class="error-page flex-center">
    <div class="container">
      <figure>
        <img src="../../assets/404.jpg" alt="404" class="error-page-image" />
      </figure>
      <p style="font-size: 14px" class="text-center">
        Oops，找不到該頁面，請點擊
        <el-button type="text" @click="$router.push('/')">返回首頁</el-button>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorPage404",
};
</script>

<style lang="scss" scoped>
.error-page {
  height: 100vh;

  .error-page-image {
    width: 1200px;
  }
}
</style>
